import React from 'react'

export type UndoIconProps = {
    size?: number | string
}

const UndoIcon: React.FC<UndoIconProps> = (props) => {
    const size = props.size || 28
    return <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.3427 23C12.6036 23 12.8049 22.9165 12.9465 22.7494C13.0882 22.5824 13.159 22.379 13.159 22.1393V17.5528H13.6286C15.3356 17.5528 16.819 17.7125 18.0788 18.0321C19.346 18.3517 20.4157 18.8637 21.2878 19.5682C22.1599 20.2727 22.8606 21.2024 23.3899 22.3572C23.5017 22.6042 23.6247 22.7712 23.7589 22.8584C23.893 22.9528 24.057 23 24.2509 23C24.4596 23 24.6347 22.9201 24.7764 22.7603C24.9255 22.6005 25 22.3826 25 22.1067C25 19.935 24.7354 18.0612 24.2061 16.4851C23.6843 14.9018 22.9277 13.5981 21.9363 12.574C20.9524 11.5499 19.7597 10.7909 18.3583 10.297C16.9569 9.79587 15.3803 9.5453 13.6286 9.5453H13.159V4.87156C13.159 4.63188 13.0882 4.42852 12.9465 4.26147C12.8049 4.08716 12.5999 4 12.3316 4C12.1526 4 11.9887 4.03995 11.8396 4.11984C11.6905 4.19973 11.5451 4.30868 11.4035 4.44667C8.59334 6.99267 5.70977 9.47678 2.95769 12.0897C2.14868 12.8578 2.14964 14.1412 2.95892 14.9091C5.71384 17.5228 8.59281 20.0143 11.4035 22.5642C11.5377 22.695 11.6793 22.8003 11.8284 22.8802C11.9849 22.9601 12.1564 23 12.3427 23Z"
            fill="currentColor"/>
    </svg>
}

export default UndoIcon






