import React from 'react'
import styled from 'styled-components'

const CopyrightContainer = styled.div`
  display: inline-flex;
  align-items: baseline;
  opacity: 0.5;
`

const CopyrightSymbolContainer = styled.span`
  margin: 0 0.3em;
`

const Copyright: React.FC = () => {
    const currentYear = new Date().getFullYear()
    return <CopyrightContainer>
        {currentYear}
        <CopyrightSymbolContainer>
            <svg width="0.75em" height="0.75em" viewBox="0 0 15 15" focusable="false">
                <path d="M7.50005 15C11.6549 15 15.0142 11.6406 15 7.5C14.9858 3.35937 11.6549 0 7.50005 0C3.37362 0 0.014249 3.35937 4.4506e-05 7.5C-0.01416 11.6406 3.37362 15 7.50005 15ZM7.50005 13.3523C4.26851 13.3523 1.63357 10.7173 1.64777 7.5071C1.66198 4.27557 4.28272 1.64773 7.50005 1.64773C10.7316 1.64773 13.3381 4.26136 13.3523 7.5071C13.3665 10.7315 10.7458 13.3523 7.50005 13.3523ZM3.35232 7.5C3.35232 9.82955 5.24152 11.7188 7.57107 11.7188C9.58811 11.7188 11.1364 10.2983 11.4063 8.40909H9.68754C9.48868 9.3821 8.66482 10.071 7.57107 10.071C6.15061 10.071 5.00004 8.92045 5.00004 7.5C5.00004 6.07955 6.15061 4.92898 7.57107 4.92898C8.66482 4.92898 9.63783 5.6179 9.82959 6.59091H11.5483C11.2785 4.7017 9.58811 3.28125 7.57107 3.28125C5.24152 3.28125 3.35232 5.17045 3.35232 7.5Z" fill="currentColor"/>
            </svg>
        </CopyrightSymbolContainer>
        Alexander Lazarev
    </CopyrightContainer>
}

export default Copyright
