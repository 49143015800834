import React from 'react'
import styled from 'styled-components'

const Link = styled.a`
  //text-decoration: underline;
`

export interface ExternalLinkProps {
    href: string
}
const ExternalLink: React.FC<ExternalLinkProps> = props => (
    <Link href={props.href} target={"_blank"}>{props.children}</Link>
)

export default ExternalLink
