import React from 'react'
import styled from 'styled-components'

const TitleContainer = styled.div`
  font-size: 1rem;
  font-style: italic;
  text-transform: uppercase;
`

const Title: React.FC = ({children}) => {
    return <TitleContainer>{children} ↘</TitleContainer>
}

export default Title
