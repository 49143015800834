import React, {useCallback, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import Toolbar, {ToolbarItem, ToolbarTool} from './toolbar'
import EraserIcon from '../../_shared/icons/eraser-icon'
import SaveIcon from '../../_shared/icons/save-icon'
import CrossIcon from '../../_shared/icons/cross-icon'
import SaveDialog from './save-dialog'
import FabricCanvas from './fabric-canvas'
import {fabric} from 'fabric'
import 'fabric-history'
import SizeSlider from './size-slider'
import {Drawing, Person} from '../../../api/client'
import PencilIcon from '../../_shared/icons/pencil-icon'
import ClearIcon from '../../_shared/icons/clear-icon'
import UndoIcon from '../../_shared/icons/undo-icon'
import RedoIcon from '../../_shared/icons/redo-icon'
import CloseDialog from './close-dialog'

const ToolbarContainer = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
`

const SaveDialogContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
`

const CloseDialogContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 590px;
`

export type PaintProps = {
    onDrawingSave: (drawing: Drawing) => void
}

const Paint: React.FC<PaintProps> = ({onDrawingSave}) => {
    const defaultToolId = "pencil"
    const [canvas, setCanvas] = useState<fabric.Canvas | null>(null)
    const [strokeWidth, setStrokeWidth] = useState(3)
    const [saveDialogVisible, setSaveDialogVisible] = useState(false)
    const [closeDialogVisible, setCloseDialogVisible] = useState(false)

    const navigate = useNavigate()
    const onCanvasInit = useCallback<(canvas: fabric.Canvas) => void>(
        (fabricCanvas) => setCanvas(fabricCanvas),
        []
    )

    const onActivateBrush = useCallback(() => {
        if (!canvas) return

        canvas.isDrawingMode = true
        canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
        canvas.freeDrawingBrush.width = strokeWidth;
    }, [canvas, strokeWidth])
    useCallback(() => {
        if (!canvas) return

        canvas.isDrawingMode = true
        canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
        canvas.freeDrawingBrush.strokeLineCap = 'square'
        canvas.freeDrawingBrush.width = strokeWidth;
    }, [canvas, strokeWidth])

    const onActivateEraser = useCallback(() => {
        if (!canvas) return

        canvas.isDrawingMode = true
        // @ts-ignore
        canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
        canvas.freeDrawingBrush.width = strokeWidth;
    }, [canvas, strokeWidth])

    const onActivateClear = useCallback(() => {
        if (!canvas) return;

        canvas.clear();
    }, [canvas]);

    const onActivateUndo = useCallback(() => {
        if (!canvas) return;

        canvas.undo();
    }, [canvas]);

    const onActivateRedo = useCallback(() => {
        if (!canvas) return;

        canvas.redo();
    }, [canvas]);

    const onActivateSave = useCallback(() => {
        if (!canvas) return

        canvas.isDrawingMode = false
        canvas.getObjects().forEach((object) => {
            object.selectable = false
            object.hasControls = false
            object.hoverCursor = 'default'
        });

        setCloseDialogVisible(false);
        setSaveDialogVisible(true);
    }, [canvas, setSaveDialogVisible])

    const onDeactivateSave = useCallback(() => setSaveDialogVisible(false), [setSaveDialogVisible])

    const onActivateExit = useCallback(() => {
        if (!canvas) return

        canvas.isDrawingMode = false
        canvas.getObjects().forEach((object) => {
            object.selectable = false
            object.hasControls = false
            object.hoverCursor = 'default'
        });

        setSaveDialogVisible(false);
        setCloseDialogVisible(true);
    }, [canvas])

    const onDeactivateExit = useCallback(() => setCloseDialogVisible(false), [])

    const onSave = useCallback((author: Person) => {
        if (!canvas) return

        onDrawingSave({
            author,
            svg: canvas.toSVG({
                suppressPreamble: true,
                viewBox: {
                    x: 0,
                    y: 0,
                    height: canvas.getHeight(),
                    width: canvas.getWidth()
                }
            })
        })
    }, [onDrawingSave, canvas])

    return <>
        <FabricCanvas onInit={onCanvasInit}/>

        {canvas && <ToolbarContainer>
            <Toolbar defaultToolId={defaultToolId}>
                <ToolbarItem>
                    <SizeSlider
                        step={2}
                        minValue={1}
                        maxValue={5}
                        value={strokeWidth}
                        onChange={(value) => setStrokeWidth(value)}
                    />
                </ToolbarItem>

                <ToolbarTool
                    id={'pencil'}
                    title={"Pencil"}
                    children={<PencilIcon/>}
                    onActivate={onActivateBrush}
                />

                <ToolbarTool
                    id={'eraser'}
                    title={"Eraser"}
                    children={<EraserIcon/>}
                    onActivate={onActivateEraser}
                />

                <ToolbarTool
                    id={'undo'}
                    title={"Undo"}
                    children={<UndoIcon/>}
                    onActivate={onActivateUndo}
                    switchToolAfterActivation={defaultToolId}
                />

                <ToolbarTool
                    id={'redo'}
                    title={"Redo"}
                    children={<RedoIcon/>}
                    onActivate={onActivateRedo}
                    switchToolAfterActivation={defaultToolId}
                />

                <ToolbarTool
                    id={'clear'}
                    title={"Clear"}
                    children={<ClearIcon/>}
                    onActivate={onActivateClear}
                    switchToolAfterActivation={defaultToolId}
                />

                <ToolbarTool
                    id={'save'}
                    title={"Save"}
                    children={<SaveIcon/>}
                    onActivate={onActivateSave}
                    onDeactivate={onDeactivateSave}
                />

                <ToolbarTool
                    id={'exit'}
                    title={"Exit"}
                    children={<CrossIcon/>}
                    onActivate={onActivateExit}
                    onDeactivate={onDeactivateExit}
                />
            </Toolbar>
        </ToolbarContainer>}

        {closeDialogVisible && <CloseDialogContainer>
            <CloseDialog onSave={() => onActivateSave()} onExit={() => navigate("/")}/>
        </CloseDialogContainer>}

        {saveDialogVisible && <SaveDialogContainer>
            <SaveDialog onSave={onSave}/>
        </SaveDialogContainer>}
    </>
}

export default Paint
