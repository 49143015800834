import React from 'react'

export type EraserIconProps = {
    size?: number | string
}

const EraserIcon: React.FC<EraserIconProps> = (props) => {
    const size = props.size || 28
    return <svg width={size} height={size} viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" fill="none" focusable="false">
        <mask id="a" fill="#fff">
            <path d="m19.414 1.414 7.172 7.172a2 2 0 0 1 0 2.828l-11 11a2 2 0 0 1-1.414.586H7.828a2 2 0 0 1-1.414-.586l-4-4a2 2 0 0 1 0-2.828L16.586 1.414a2 2 0 0 1 2.828 0Z"/>
        </mask>
        <path stroke="#000" strokeLinejoin="round" strokeWidth="6"
              d="m19.414 1.414 7.172 7.172a2 2 0 0 1 0 2.828l-11 11a2 2 0 0 1-1.414.586H7.828a2 2 0 0 1-1.414-.586l-4-4a2 2 0 0 1 0-2.828L16.586 1.414a2 2 0 0 1 2.828 0Z" mask="url(#a)"/>
        <path fill="#000" d="m17 18-7-7 8-8 7 7-8 8ZM1 26a1 1 0 0 1 1-1h24a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-1Z"/>
    </svg>
}

export default EraserIcon
