import React from 'react'

export type DrawIconProps = {
    size?: number | string
}

const DrawIcon: React.FC<DrawIconProps> = (props) => {
    const size = props.size || 28
    return <svg width={size} height={size} viewBox="0 0 28 28" focusable="false">
        <path
            d="M26.836 5.393 21.66.217a.742.742 0 0 0-1.049 0L7.743 13.086a.743.743 0 0 0-.203.38l-1.283 6.458a.742.742 0 0 0 .873.872l6.457-1.283a.742.742 0 0 0 .38-.203L26.836 6.442c.29-.29.29-.76 0-1.05ZM8.228 17.617l.67-3.376 3.914 3.914-3.376.67-1.208-1.208ZM20.367 22.459c-7.095-2.97-11.713 1.941-13.093 2.687-1.763.954-4.247 2.075-4.824.135-.349-1.17 1.535-2.421 2.782-2.811.91-.285.521-1.717-.394-1.43C2.9 21.644.59 23.275.995 25.572c.301 1.707 2.44 3.263 6.564 1.1 2.998-1.574 5.034-4.831 11.348-3.144 2.074.554 3.75 1.905 4.469 3.962.313.897 1.746.51 1.43-.395-.765-2.191-2.313-3.747-4.44-4.637Z"
            fill="currentColor"/>
    </svg>
}

export default DrawIcon
