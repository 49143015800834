import styled from 'styled-components'
import {MEDIA_BREAKPOINTS} from '../../const'

const Grid = styled.div`
  display: grid;
  gap: 1rem;
  padding: 1rem;
  box-sizing: border-box;
  grid-template-columns: repeat(6, 1fr);

  @media (${MEDIA_BREAKPOINTS.LAPTOP}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (${MEDIA_BREAKPOINTS.TABLET}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (${MEDIA_BREAKPOINTS.MOBILE}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export default Grid
