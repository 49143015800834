import React from 'react'
import styled from 'styled-components'

const PreloaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 999999;
`

const Spinner = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-left: 4px solid #000;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;

  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`

const Preloader: React.FC = () => (
    <PreloaderWrapper children={<Spinner/>}/>
)

export default Preloader;
