import React from 'react'

export type SaveIconProps = {
    size?: number | string
}

const SaveIcon: React.FC<SaveIconProps> = (props) => {
    const size = props.size || 28
    return <svg width={size} height={size} viewBox="0 0 28 28" focusable="false">
        <g fill="currentColor" clipPath="url(#a)">
            <path d="M24.172 1.172A4 4 0 0 0 21.343 0H19c.024.577.012 4.214.005 6.005a.999.999 0 0 1-1 .995H5a1 1 0 0 1-1-1V0H2a2 2 0 0 0-2 2v24a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V6.657a4 4 0 0 0-1.172-2.829l-2.656-2.656ZM24 24H4V14h20v10Z"/>
            <path d="M16 0h-4v5h4V0Z"/>
        </g>
        <defs>
            <clipPath id="a">
                <path fill="currentColor" d="M0 0h28v28H0z"/>
            </clipPath>
        </defs>
    </svg>
}

export default SaveIcon
