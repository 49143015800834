import styled from 'styled-components'
import {Media, MediaType} from '../../api/client'
import React from 'react'

const ResponsiveImage = styled.img`
  object-fit: contain;
  object-position: center;
  margin-left: -1rem;
  width: calc(100% + 2rem);
`

const ResponsiveVideo = styled.video`
  margin-left: -1rem;
  width: calc(100% + 2rem);
`

export type ResponsiveMediaProps = {
    media: Media
}

const ResponsiveMedia: React.FC<ResponsiveMediaProps> = ({media}) => {
    switch (media.type) {
        case MediaType.Image:
            return <ResponsiveImage src={media.url}/>
        case MediaType.Video:
            return <ResponsiveVideo src={media.url} autoPlay={true} muted={true} loop={true} controls={false} playsInline={true}/>
    }

    return null;
}

export default ResponsiveMedia
