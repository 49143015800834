import styled from 'styled-components'
import React from 'react'
import Title from '../../_shared/title'
import Button from '../../_shared/button'

type DrawAgreementProps = {
    onSave: Function,
    onExit: Function,
}

const CloseDialogContainer = styled.div`
  padding: 1rem;
  background: #fff;
`

const CloseDialogContent = styled.div`
  > p:not(:first-child) {
    margin-top: 1.5rem;
  }
`

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`

const ActionButton = styled(Button)`
  width: 100%;
`


const CloseDialog: React.FC<DrawAgreementProps> = (props) => {
    return <CloseDialogContainer>
        <Title>Info</Title>
        <CloseDialogContent>
            <p>Exit without saving? The drawing will be permanently deleted.</p>
        </CloseDialogContent>
        <ActionButtonsContainer>
            <ActionButton onClick={() => props.onExit()}>Exit</ActionButton>
            <ActionButton onClick={() => props.onSave()}>Save</ActionButton>
        </ActionButtonsContainer>
    </CloseDialogContainer>
}

export default CloseDialog
