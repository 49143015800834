import styled from 'styled-components'

const TextField = styled.input`
  box-sizing: border-box;
  padding: .525em;

  width: 100%;
  
  border: 2px solid #000;
  border-radius: 4px;
  outline: none;

  &::placeholder {
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
  }
`

export default TextField
