import React from 'react'

export type ClearIconProps = {
    size?: number | string
}

const ClearIcon: React.FC<ClearIconProps> = (props) => {
    const size = props.size || 28
    return <svg width={size} height={size} viewBox="0 0 28 28" fill="none" focusable="false">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M6.82338 28H21.1761C22.2093 27.9986 23.0715 27.2103 23.1651 26.1811L24.6364 9.99633L25.4977 9.99767C26.327 9.99895 27 9.327 27 8.49767V6.5C27 5.67157 26.3284 5 25.5 5H21.3141L20.812 2.74074C20.4562 1.13936 19.0358 0 17.3954 0H10.6041C8.96363 0 7.54328 1.13936 7.18742 2.74074L6.68537 5C5.95157 5 3.92353 5 2.49986 5C1.6714 5 1.00004 5.67162 1.00008 6.50008L1.00019 8.49641C1.00023 9.3248 1.67179 9.99633 2.50019 9.99633H3.36304L4.83438 26.1811C4.92794 27.2103 5.79021 27.9986 6.82338 28ZM10.6041 3C10.3697 3 10.1668 3.16277 10.116 3.39153L9.75855 5H18.2409L17.8835 3.39153C17.8326 3.16277 17.6297 3 17.3954 3H10.6041ZM8.94429 13.0016C9.49573 12.9709 9.96759 13.3931 9.99822 13.9446L10.4982 22.9446C10.5289 23.496 10.1067 23.9679 9.55523 23.9985C9.0038 24.0291 8.53194 23.6069 8.5013 23.0555L8.0013 14.0555C7.97067 13.5041 8.39286 13.0322 8.94429 13.0016ZM19.0552 13.0016C19.6067 13.0322 20.0289 13.5041 19.9982 14.0555L19.4982 23.0555C19.4676 23.6069 18.9957 24.0291 18.4443 23.9985C17.8929 23.9679 17.4707 23.496 17.5013 22.9446L18.0013 13.9446C18.0319 13.3931 18.5038 12.9709 19.0552 13.0016ZM13.9998 13C14.552 13 14.9998 13.4477 14.9998 14V23C14.9998 23.5523 14.552 24 13.9998 24C13.4475 24 12.9998 23.5523 12.9998 23V14C12.9998 13.4477 13.4475 13 13.9998 13Z"
              fill="currentColor"/>
    </svg>
}

export default ClearIcon
