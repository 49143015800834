import React from 'react'
import styled from 'styled-components'
import {Media} from '../../api/client'
import ResponsiveMedia from '../_shared/responsive-media'

export type WorksMediaListProps = {
    items: Media[]
}

const WorksMediaListContainer = styled.div`
  display: flex;
  gap: 0px;
  flex-direction: column;
`

const WorksMediaList: React.FC<WorksMediaListProps> = ({items}) => {
    const media = items.map(media => <ResponsiveMedia key={media.url} media={media}/>)
    return <WorksMediaListContainer>{media}</WorksMediaListContainer>
}

export default WorksMediaList
