import {API_URL} from '../const'

export type Person = {
    name: string,
    email?: string
}
export type Drawing = {
    author: Person,
    svg: string
}

export enum MediaType {
    Image = "image",
    Video = "video"
}

export type Media = {
    url: string,
    type: MediaType
}

export type Work = {
    id: number,
    title: string,
    tags: string[],
    preview: Media
}

export type WorkClient = {
    id: number,
    name: string,
    url?: string
}

export type WorkDetails = {
    id: number,
    title: string,
    description: string,
    tags: string[],
    clients: WorkClient[],
    media: Media[],
    prev_work_id?: number,
    next_work_id?: number,
}

export type Experience = {
    id: number,
    title: string
    description: string
    startDate: Date
    endDate?: Date,
    type: ExperienceType
}

export enum ExperienceType {
    SPEECH= 'speech',
    EXPERIENCE = 'experience'
}

export type Speech = {
    id: number,
    title: string
    startDate: Date
    endDate?: Date
}

export type AboutMe = {
    text: string,
    experiences: Experience[],
    speeches: Speech[]
}

export const apiClient = {
    tryGetRandomDrawing(): Promise<Drawing | null> {
        return fetch(`${API_URL}/drawings/random`)
            .then(response => response.json())
    },

    saveDrawing(drawing: Drawing): Promise<void> {
        return fetch(`${API_URL}/drawings/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(drawing)
        }).then()
    },

    listAllWorks(): Promise<Work[]> {
        return fetch(`${API_URL}/works`)
            .then(response => response.json())
    },

    listAllWorksMedia(): Promise<Media[]> {
        return fetch(`${API_URL}/works-media`)
            .then(response => response.json())
    },

    getWorkById(id: number): Promise<WorkDetails> {
        return fetch(`${API_URL}/works/${id}`)
            .then(response => response.json())
    },

    getAboutInfo(): Promise<AboutMe> {
        return fetch(`${API_URL}/about/me`)
            .then(response => response.json())
            .then(data => {
                return {
                    ...data,
                    experiences: data.experiences.map((experience: any): Experience => ({
                        ...experience,
                        startDate: new Date(experience.startDate),
                        endDate: experience.endDate ? new Date(experience.endDate) : undefined
                    })),
                    speeches: data.speeches.map((speech: any): Speech => ({
                        id: speech.id,
                        title: speech.title,
                        startDate: new Date(speech.startDate),
                        endDate: speech.endDate ? new Date(speech.endDate) : undefined
                    }))
                }
            });
    },
}
