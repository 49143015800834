import React, {useEffect, useState} from 'react'
import Copyright from '../_shared/copyright'
import styled from 'styled-components'
import ExternalLink from '../_shared/external-link'
import {Link} from 'react-router-dom'
import EmailLink from '../_shared/email-link'
import Grid from '../_shared/grid'
import DrawIcon from '../_shared/icons/draw-icon'
import IconLink from '../_shared/icon-link'
import {apiClient, Drawing} from '../../api/client'
import {MEDIA_BREAKPOINTS} from '../../const'

const GreetingContainer = styled.div`grid-area: G;`
const CurrentWorkContainer = styled.div`grid-area: W;`
const AboutMeContainer = styled.div`
    grid-area: A;
    margin-top: 4rem;
    margin-bottom: 2rem;

    @media (${MEDIA_BREAKPOINTS.MOBILE}) {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
`
const NavigationContainer = styled.nav`
    grid-area: N;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;

    @media (${MEDIA_BREAKPOINTS.MOBILE}) {
        margin-bottom: 1rem;
    }
`
const CopyrightContainer = styled.div`
    grid-area: C;
    align-self: end;
`
const EmailLinkContainer = styled.div`
    grid-area: E;
    align-self: end;
`

const RefreshBackgroundLinkContainer = styled.div`
    text-align: right;

    @media (${MEDIA_BREAKPOINTS.MOBILE}) {
        display: none;
    }
`

const SocialLinksContainer = styled.nav`
    grid-area: S;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media (${MEDIA_BREAKPOINTS.TABLET}) {
        align-self: end;
        flex-direction: row;
        gap: 1rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
`
const DrawContainer = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    grid-area: D;
    align-self: end;
    justify-self: end;

    @media (${MEDIA_BREAKPOINTS.TABLET}) {
        display: none;
    }
`

const DrawAuthorDetails = styled.div`
    opacity: 0.5;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

const HomePageLayout = styled(Grid)<{ svg?: string }>`
    min-height: 100vh;

    &:before {
        display: block;
        content: "";
        position: fixed;
        z-index: -10000;
        background: ${props => props.svg ? `url('data:image/svg+xml;base64,${btoa(props.svg)}')` : 'transparent'} no-repeat center;
        background-size: contain;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.9;
    }

    grid-template-rows: repeat(3, auto) 1fr;
    grid-template-areas: 
          "G G . S . N"
          ". . A A . ."
          ". W W . . ."
          "C C . E D D";

    @media (${MEDIA_BREAKPOINTS.LAPTOP}) {
        grid-template-areas: 
          "G G S N"
          ". A A ."
          "W W . ."
          "C C E D";
    }

    @media (${MEDIA_BREAKPOINTS.TABLET}) {
        grid-template-rows: repeat(2, auto) 1fr repeat(3, auto); /*todo*/
        grid-template-areas: 
          "G G N"
          "A A ."
          "W W ."
          "E E E"
          "S S S"
          "C C C";

        &:before {
            display: none;
        }
    }

    @media (${MEDIA_BREAKPOINTS.MOBILE}) {
        grid-template-rows: auto auto auto 1fr repeat(3, auto);
        grid-template-areas: 
          "N N"
          "G G"
          "A A"
          "W W"
          "E E"
          "S S"
          "C C";
    }
`

const HomePage: React.FC = () => {
    const [drawing, setDrawing] = useState<Drawing | null>(null)
    const loadNewDrawing = () => {
        apiClient.tryGetRandomDrawing()
            .then(data => setDrawing(data))
    }

    useEffect(loadNewDrawing, [])

    return <HomePageLayout svg={drawing?.svg}>
        <GreetingContainer>Hi, it's me, Sasha.</GreetingContainer>

        <SocialLinksContainer>
            <ExternalLink href="https://www.facebook.com/sashalazarhere">Facebook</ExternalLink>
            <ExternalLink href="https://linkedin.com/in/sashalazar">LinkedIn</ExternalLink>
            <ExternalLink href="https://linktr.ee/sashalazar">Other</ExternalLink>


        </SocialLinksContainer>

        <NavigationContainer>
            <Link to="/about">About</Link>
            <Link to="/works">Works</Link>
        </NavigationContainer>

        <AboutMeContainer>
            I am a multidisciplinary designer with experience in graphic design, branding and interfaces, focused on
            creating useful and beautiful design.
        </AboutMeContainer>

        <CurrentWorkContainer>
            Currently working as a Senior Product designer at <ExternalLink
            href="https://apps.apple.com/ru/app/%D0%B1%D0%BA%D1%81-%D0%BC%D0%B8%D1%80-%D0%B8%D0%BD%D0%B2%D0%B5%D1%81%D1%82%D0%B8%D1%86%D0%B8%D0%B9/id1033882791">BCS</ExternalLink>.
            Ex.&nbsp;Wink by Rostelecom.
            <p>Open for your business.</p>
            <RefreshBackgroundLinkContainer>
                <Link to={"#"} onClick={loadNewDrawing}>Click to shuffle the drawings</Link>
            </RefreshBackgroundLinkContainer>
        </CurrentWorkContainer>

        <CopyrightContainer><Copyright/></CopyrightContainer>
        <EmailLinkContainer><EmailLink email={"hello@sashalazar.design"}/></EmailLinkContainer>

        <DrawContainer>
            {drawing?.author
                ? <DrawAuthorDetails>Art by {drawing.author.name}</DrawAuthorDetails>
                : <span>&nbsp;</span>}

            <IconLink href={'/draw'} icon={<DrawIcon size={'1em'}/>}/>
        </DrawContainer>
    </HomePageLayout>
}

export default HomePage
