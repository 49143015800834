import styled from 'styled-components'
import React from 'react'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const formatDate = (date: Date): string => {
    return `${months[date.getMonth()]} ${date.getFullYear()}`
}

const DatePeriodContainer = styled.span`
  font-style: italic;
  opacity: 0.5;
`

export interface DatePeriodProps {
    startDate: Date
    endDate?: Date,
}

const DatePeriod: React.FC<DatePeriodProps> = ({startDate, endDate}) => {
    if (+startDate === +(endDate || 0)) {
        return <DatePeriodContainer>({formatDate(startDate)})</DatePeriodContainer>
    }

    return <DatePeriodContainer>({formatDate(startDate)} – {endDate ? formatDate(endDate) : 'present'})</DatePeriodContainer>
}

export default DatePeriod
