import React, {useEffect, useRef, useState} from 'react'
import {fabric} from 'fabric'
import 'fabric-history'
import styled from 'styled-components'

const CanvasContainer = styled.div`
  width: 100vw;
  height: 100vh;
`

export type FabricCanvasProps = {
    onInit: (canvas: fabric.Canvas) => void
}

const FabricCanvas: React.FC<FabricCanvasProps> = ({onInit}) => {
    const canvasContainerRef = useRef<HTMLDivElement>(null)
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const [fabricCanvas, setFabricCanvas] = useState<fabric.Canvas | null>(null)

    useEffect(() => {
        if (!canvasRef.current) return;
        const fabricCanvas = new fabric.Canvas(canvasRef.current, {selection: false})

        const resizeCanvas = () => {
            fabricCanvas.setHeight(canvasContainerRef.current?.clientHeight || 0)
            fabricCanvas.setWidth(canvasContainerRef.current?.clientWidth || 0)
            fabricCanvas.renderAll()
        }

        const handleKeyboardCombinations = (event: KeyboardEvent) => {
            if (!(event.keyCode === 90 && (event.metaKey || event.ctrlKey))) {
                return;
            }

            if (event.shiftKey) {
                fabricCanvas.redo()
            } else {
                fabricCanvas.undo()
            }
        }

        resizeCanvas()
        window.addEventListener('resize', resizeCanvas, false)
        window.addEventListener('keydown', handleKeyboardCombinations);

        setFabricCanvas(fabricCanvas)
        return () => {
            fabricCanvas.dispose()
            window.removeEventListener('resize', resizeCanvas)
            window.removeEventListener('keydown', handleKeyboardCombinations);
            setFabricCanvas(null)
        }
    }, [])

    useEffect(() => {
        if (fabricCanvas) {
            onInit(fabricCanvas)
        }
    }, [fabricCanvas, onInit])

    return <CanvasContainer ref={canvasContainerRef}>
        <canvas ref={canvasRef}/>
    </CanvasContainer>
}

export default FabricCanvas
