import React from 'react'

export type CrossIconProps = {
    size?: number | string
}

const CrossIcon: React.FC<CrossIconProps> = (props) => {
    const size = props.size || 28
    return <svg width={size} height={size} viewBox="0 0 28 28" focusable="false">
        <path fill="currentColor" stroke="currentColor" strokeWidth=".5" d="m25.293 4.293-1.586-1.586a1 1 0 0 0-1.414 0L14 11 5.707 2.707a1 1 0 0 0-1.414 0L2.707 4.293a1 1 0 0 0 0 1.414L11 14l-8.293 8.293a1 1 0 0 0 0 1.414l1.586 1.586a1 1 0 0 0 1.414 0L14 17l8.293 8.293a1 1 0 0 0 1.414 0l1.586-1.586a1 1 0 0 0 0-1.414L17 14l8.293-8.293a1 1 0 0 0 0-1.414Z"/>
    </svg>
}

export default CrossIcon
