import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Grid from '../_shared/grid'
import photo from '../../assets/images/photo.png'
import BackToMainLink from '../_shared/back-to-main-link'
import Copyright from '../_shared/copyright'
import ExperienceList from './experience-list'
import Title from '../_shared/title'
import {AboutMe, apiClient, ExperienceType} from '../../api/client'
import Preloader from '../_shared/preloader'
import {MEDIA_BREAKPOINTS} from '../../const'

const AboutPageMobileHeader = styled.div`
  display: none;

  @media (${MEDIA_BREAKPOINTS.TABLET}) {
    display: flex;
    grid-area: M;
    justify-content: space-between;
  }
`

const SectionTitleContainer = styled.div`margin-bottom: 1rem`
const Section = styled.div`margin-bottom: 2rem`

const BackToMainLinkContainer = styled.div`
  grid-area: B;
  justify-self: end;

  @media (${MEDIA_BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const AboutContainer = styled.div`
  grid-area: A;
`

const AboutSectionTitleContainer = styled(SectionTitleContainer)`
  @media (${MEDIA_BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const AboutSectionDescription = styled.div`
  @media (${MEDIA_BREAKPOINTS.TABLET}) {
    margin-top: 0;
  }
`

const ExperienceContainer = styled.div`
  grid-area: E;
`

const PhotoContainer = styled.div`
  grid-area: P;
  align-self: start;
  justify-self: center;
  width: 100%;
`

const Photo = styled.div<any>`
  background: url("${props => props.src}") no-repeat top center;
  background-size: contain;
  min-height: 85vh;
  width: 100%;
`

const CopyrightContainer = styled.div`
  grid-area: C;
  align-self: end;

  @media (${MEDIA_BREAKPOINTS.TABLET}) {
    align-self: end;
  }
`

const AboutPageLayout = styled(Grid)`
  min-height: 100vh;
  align-content: start;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 
          "A A E E B B"
          "A A E E P P"
          "C C E E P P";

  @media (${MEDIA_BREAKPOINTS.LAPTOP}) {
    grid-template-rows: auto 1fr auto auto;
    grid-template-areas: 
          "A A B B"
          "A A P P"
          "E E P P"
          "C C P P";
  }

  @media (${MEDIA_BREAKPOINTS.TABLET}) {
    grid-template-rows: repeat(5, auto);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 
          "M M"
          "A A"
          "E E"
          "P P"
          "C C";
  }
`

const AboutPage: React.FC = () => {
    const [aboutInfo, setAbountInfo] = useState<AboutMe | undefined>(undefined)

    useEffect(() => {
        apiClient.getAboutInfo().then(data => setAbountInfo(data))
    }, []);

    if (aboutInfo === undefined) {
        return <Preloader />
    }

    return <AboutPageLayout>

        <AboutPageMobileHeader>
            <Title>About</Title>
            <BackToMainLink/>
        </AboutPageMobileHeader>

        <AboutContainer>
            <Section>
                <AboutSectionTitleContainer>
                    <Title>About</Title>
                </AboutSectionTitleContainer>
                <AboutSectionDescription>{aboutInfo.text.split("\n").map((p, index) => <p key={index}>{p}</p>)}</AboutSectionDescription>
            </Section>
            <Section>
                <SectionTitleContainer>
                    <Title>Speech</Title>
                </SectionTitleContainer>
                <ExperienceList items={aboutInfo.experiences.filter((e) => e.type === ExperienceType.SPEECH)}/>
            </Section>
        </AboutContainer>

        <ExperienceContainer>
            <Section>
                <SectionTitleContainer>
                    <Title>Experience</Title>
                </SectionTitleContainer>
                <ExperienceList items={aboutInfo.experiences.filter((e) => e.type === ExperienceType.EXPERIENCE)}/>
            </Section>
        </ExperienceContainer>

        <BackToMainLinkContainer>
            <BackToMainLink/>
        </BackToMainLinkContainer>

        <PhotoContainer>
            <Photo src={photo}/>
        </PhotoContainer>

        <CopyrightContainer>
            <Copyright/>
        </CopyrightContainer>
    </AboutPageLayout>
}

export default AboutPage
