import styled from 'styled-components'
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'

type ToolId = string
type ToolbarContextState = [ToolId | undefined, (key: ToolId) => void]
const ToolbarContext = React.createContext<ToolbarContextState>([undefined, () => {
}])

const ToolbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 48px;

  background: #fff;
  border: 2px solid #000;
  border-radius: 4px;
`

const ItemContainer = styled.div`
  padding: 10px;
  border-top: 2px solid;
  line-height: 0;

  &:first-child {
    border-top: none;
  }
`

const ToolContainer = styled(ItemContainer)<{ active: boolean }>`
  color: #000;
  background: #fff;
  cursor: pointer;
  border-color: ${props => props.active ? 'transparent' : '#000'};
  filter: ${props => props.active ? 'invert(100%)' : 'none'};

  &:active {
    border-color: transparent;
    filter: invert(100%);
  }
`

export type ToolbarToolProps = {
    id: string
    title: string,
    onActivate: () => void
    onDeactivate?: () => void,
    switchToolAfterActivation?: ToolId
}

export const ToolbarTool: React.FC<ToolbarToolProps> = ({id, title, onActivate, onDeactivate, switchToolAfterActivation, children}) => {
    const [activeToolId, setActiveToolId] = useContext(ToolbarContext)
    const isActive = useCallback(() => id === activeToolId, [id, activeToolId])

    useEffect(() => {
        if (isActive()) {
            onActivate()
            if (switchToolAfterActivation) {
                setActiveToolId(switchToolAfterActivation)
            }
        }
        return () => {
            if (isActive() && typeof onDeactivate === 'function') onDeactivate()
        }
    }, [id, isActive, onActivate, onDeactivate, setActiveToolId, switchToolAfterActivation])

    return <ToolContainer
        title={title}
        active={activeToolId === id}
        onClick={() => setActiveToolId(id)}>
        {children}
    </ToolContainer>
}

export const ToolbarItem: React.FC = ({children}) => {
    return <ItemContainer>{children}</ItemContainer>
}

export type ToolbarProps = {
    defaultToolId?: ToolId
}

const Toolbar: React.FC<ToolbarProps> = ({defaultToolId, children}) => {
    const [id, setId] = useState(defaultToolId)
    const setToolId = useCallback((value) => {
        setId(() => {
            if (value !== id) return value;
            return id
        })
    }, [setId, id])

    const value = useMemo<ToolbarContextState>(() => [id, setToolId], [id, setToolId])

    return <ToolbarContainer>
        <ToolbarContext.Provider value={value}>
            {children}
        </ToolbarContext.Provider>
    </ToolbarContainer>
}

export default Toolbar
