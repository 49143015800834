import React from 'react'

export type PencilIconProps = {
    size?: number | string
}

const PencilIcon: React.FC<PencilIconProps> = (props) => {
    const size = props.size || 28
    return <svg width={size} height={size} viewBox="0 0 28 28" focusable="false">
        <path fill="currentColor" fillRule="evenodd" d="m3.7 19.7 4.6 4.6-3.75.75-1.6-1.6.75-3.75ZM17.879 2.12 3.414 16.586l-.557.558a4 4 0 0 0-1.094 2.044L.294 26.529a1 1 0 0 0 1.177 1.177l7.342-1.468a4 4 0 0 0 2.044-1.094l.557-.558L25.88 10.12l.707-.707a2 2 0 0 0 0-2.828l-.706-.707h-.001L22.12 2.12l-.707-.707a2 2 0 0 0-2.828 0l-.706.706-.001.001Z" clipRule="evenodd"/>
    </svg>
}

export default PencilIcon
