import React, {useState} from 'react'
import {Direction, Range} from 'react-range'
import styled from 'styled-components'

export type SizeRangeProps = {
    step: number
    minValue: number
    maxValue: number
    value: number
    onChange: (newValue: number) => void
}

const SizeSliderRenderTrack = styled.div`
  height: 60px;
  width: 12px;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 60'%3E%3Cpath d='M9.8264 0H2.1736C1.00352 0 0.0833377 1.00005 0.180508 2.16609L4.92359 59.083C4.96678 59.6013 5.40004 60 5.92013 60H6.07987C6.59996 60 7.03322 59.6013 7.07641 59.083L11.8195 2.16609C11.9167 1.00005 10.9965 0 9.8264 0Z' fill='black'/%3E%3C/svg%3E%0A");
`

const SizeSliderRenderThumb = styled.div`
  height: 12px;
  width: 12px;
  background-color: #000;
  border-radius: 100%;
  border: 2px solid #fff;
  outline: none;
`

const SizeSlider: React.FC<SizeRangeProps> = ({step, minValue, maxValue, value, onChange}) => {
    const [values, setValues] = useState([value])
    return (
        <Range
            direction={Direction.Up}
            step={step}
            min={minValue}
            max={maxValue}
            values={values}
            onChange={(values) => setValues(values)}
            onFinalChange={() => onChange(values[0])}
            renderTrack={({props, children}) => (
                <SizeSliderRenderTrack {...props}>{children}</SizeSliderRenderTrack>
            )}
            renderThumb={({props}) => (
                <SizeSliderRenderThumb {...props}/>
            )}
        />
    );
}

export default SizeSlider
