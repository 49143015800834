import React from 'react'

export type RedoIconProps = {
    size?: number | string
}

const RedoIcon: React.FC<RedoIconProps> = (props) => {
    const size = props.size || 28
    return <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.6573 23C15.3964 23 15.1951 22.9165 15.0535 22.7494C14.9118 22.5824 14.841 22.379 14.841 22.1393V17.5528H14.3714C12.6644 17.5528 11.181 17.7125 9.92125 18.0321C8.65403 18.3517 7.58435 18.8637 6.7122 19.5682C5.84006 20.2727 5.13936 21.2024 4.61011 22.3572C4.4983 22.6042 4.3753 22.7712 4.24113 22.8584C4.10695 22.9528 3.94296 23 3.74915 23C3.54043 23 3.36526 22.9201 3.22363 22.7603C3.07454 22.6005 3 22.3826 3 22.1067C3 19.935 3.26463 18.0612 3.79387 16.4851C4.31567 14.9018 5.07227 13.5981 6.06369 12.574C7.04764 11.5499 8.24032 10.7909 9.64171 10.297C11.0431 9.79587 12.6197 9.5453 14.3714 9.5453H14.841V4.87156C14.841 4.63188 14.9118 4.42852 15.0535 4.26147C15.1951 4.08716 15.4001 4 15.6684 4C15.8474 4 16.0113 4.03995 16.1604 4.11984C16.3095 4.19973 16.4549 4.30868 16.5965 4.44667C17.7301 5.47369 22.3879 9.73579 24.8911 12.027C25.7567 12.8194 25.7571 14.1811 24.892 14.9739C22.3893 17.2674 17.7311 21.5349 16.5965 22.5642C16.4623 22.695 16.3207 22.8003 16.1716 22.8802C16.0151 22.9601 15.8436 23 15.6573 23Z"
            fill="currentColor"/>
    </svg>
}

export default RedoIcon



