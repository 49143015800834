import "./assets/styles/normalize.css"
import "./assets/styles/style.css"

import React from 'react';
import {BrowserRouter, Routes} from "react-router-dom";
import {Route} from 'react-router'
import HomePage from './view/home'
import AboutPage from './view/about'
import DrawPage from './view/draw'
import NotFoundPage from './view/not-found'
import WorksMediaPage from "./view/works-media";
// import WorksPage from './view/works'
// import WorkDetailsPage from './view/work-details'

const App: React.FC = () => {
    return <BrowserRouter>
        <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/about" element={<AboutPage/>}/>
            <Route path="/draw" element={<DrawPage/>}/>
            <Route path="/works" element={<WorksMediaPage/>}/>
            {/*<Route path="/works" element={<WorksPage/>}/>*/}
            {/*<Route path="/works/:workId" element={<WorkDetailsPage/>}/>*/}
            <Route path="*" element={<NotFoundPage/>}/>
        </Routes>
    </BrowserRouter>
}

export default App;
