import styled from 'styled-components'

const Button = styled.button`
  display: inline-flex;

  box-sizing: border-box;
  padding: .525em 2em;

  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;

  color: #fff;
  border: 2px solid #000;
  border-radius: 4px;
  outline: none;
  background: #000;

  align-content: center;
  justify-content: center;

  &:active {
    color: #000;
    background-color: #fff;
    border-color: #000;
  }
`

export default Button
