import React from 'react'
import Grid from '../_shared/grid'
import styled from 'styled-components'
import Title from '../_shared/title'
import Button from '../_shared/button'
import {MEDIA_BREAKPOINTS} from '../../const'

type DrawAgreementProps = {
    onAccept: Function
}

const DrawAgreementLayout = styled(Grid)`
  min-height: 100vh;
`

const DrawAgreementContainer = styled.div`
  align-self: end;
  grid-column: 3/5;

  @media (${MEDIA_BREAKPOINTS.LAPTOP}) {
    grid-column: 2/4;
  }

  @media (${MEDIA_BREAKPOINTS.TABLET}) {
    grid-column: 1/-1
  }
`

const DrawAgreementDetails = styled.div`
  > p:not(:first-child) {
    margin-top: 1.5rem;
  }
`

const ConfirmButtonContainer = styled.div`
  align-self: start;
  grid-column: 3/4; // todo
`

const ConfirmButton = styled(Button)`
  width: 100%;
`

const DrawAgreement: React.FC<DrawAgreementProps> = (props) => {
    return <DrawAgreementLayout>
        <DrawAgreementContainer>
            <Title>Info</Title>
            <DrawAgreementDetails>
                <p>The basic idea is that your drawing can become a part of my website design. The drawings will be displayed in random order every time you visit the site. </p>
                <p>I personally decide whether to keep your drawing or not. Draw nicely, don't draw ugly.</p>
            </DrawAgreementDetails>
        </DrawAgreementContainer>

        <ConfirmButtonContainer>
            <ConfirmButton onClick={() => props.onAccept()}>I agree</ConfirmButton>
        </ConfirmButtonContainer>
    </DrawAgreementLayout>
}

export default DrawAgreement
