import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'

const LinkContainer = styled.span`
  line-height: 0;
`

export interface IconLinkProps {
    href: string,
    icon: React.ReactElement
}

const IconLink: React.FC<IconLinkProps> = props => (
    <LinkContainer children={<Link to={props.href}>{props.icon}</Link>}/>
)

export default IconLink
