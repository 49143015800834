import styled from 'styled-components'
import React from 'react'
import DatePeriod from '../_shared/date-period'
import {Experience} from '../../api/client'

export type ExperienceListProps = {
    items: Experience[]
}

const ExperienceListItem = styled.div`
  border-bottom: 1px solid #000;
  margin-bottom: 1rem;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`

const ExperienceDescriptionContainer = styled.div`
  margin-top: 0.6rem;

  p:first-child {
    padding-top: 0;
    margin-top: 0;
  }
`

const ExperienceList: React.FC<ExperienceListProps> = ({items}) => {
    return <div>
        {items
            .sort((e1, e2) => {
                if (e1.id === 1 || e2.id === 1) return e2.id - e1.id; // первый ID всегда внизу

                const startDifference = +e2.startDate - +e1.startDate
                if (e1.endDate === undefined || e2.endDate === undefined) {
                    if (e1.endDate === undefined && e2.endDate === undefined) return startDifference;

                    return e1.endDate === undefined ? -1 : 1
                }

                return startDifference === 0
                    ? +e2.endDate - +e1.endDate
                    : startDifference;
            })
            .map((experience) => <ExperienceListItem key={experience.id}>
                {experience.title} <DatePeriod startDate={experience.startDate} endDate={experience.endDate}/>
                <ExperienceDescriptionContainer>
                    {experience.description
                        ? experience.description.split("\n").map((p, index) => <p key={index}>{p}</p>)
                        : <p></p>}
                </ExperienceDescriptionContainer>
            </ExperienceListItem>)}
    </div>
}

export default ExperienceList
