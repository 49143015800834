import React, {useCallback, useState} from 'react'
import DrawAgreement from './draw-agreement'
import Paint from './paint'
import {useNavigate} from 'react-router-dom'
import {apiClient} from '../../api/client'
import Preloader from '../_shared/preloader'

const DRAW_AGREEMENT_STORAGE_KEY = "draw-agreement-accepted"
const DRAW_AGREEMENT_ACCEPTED_VALUE = "true"

const DrawPage: React.FC = () => {
    const [saving, setSaving] = useState(false);
    const [agreementAccepted, setAgreementAccepted] = useState(localStorage.getItem(DRAW_AGREEMENT_STORAGE_KEY) === DRAW_AGREEMENT_ACCEPTED_VALUE)
    const navigate = useNavigate()
    const acceptAgreement = () => {
        localStorage.setItem(DRAW_AGREEMENT_STORAGE_KEY, DRAW_AGREEMENT_ACCEPTED_VALUE)
        setAgreementAccepted(true)
    }

    const onDrawingSave = useCallback((drawing) => {
        setSaving(true);
        apiClient.saveDrawing(drawing)
            .then(() => navigate("/"))
            .catch(() => alert("При сохранении произошла ошибка."))
            .finally(() => setSaving(false))
    }, [navigate])

    if (saving) {
        return <Preloader/>
    }

    return agreementAccepted
        ? <Paint onDrawingSave={onDrawingSave}/>
        : <DrawAgreement onAccept={acceptAgreement}/>
}

export default DrawPage
