import React from 'react'
import ExternalLink from './external-link'

type EmailLinkProps = {
    email: string,
    text?: string
}

const EmailLink: React.FC<EmailLinkProps> = ({email, text}) => {
    return <ExternalLink href={`mailto:${email}`}>{text ? text : email}</ExternalLink>
}

export default EmailLink
