import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Grid from '../_shared/grid'
import BackToMainLink from '../_shared/back-to-main-link'
import Copyright from '../_shared/copyright'
import WorksMediaList from './works-media-list'
import {apiClient, Media} from '../../api/client'
import Title from '../_shared/title'
import Preloader from '../_shared/preloader'

const BackToMainLinkContainer = styled.nav`
  grid-area: B;
  display: flex;
  justify-content: flex-end;
`

const WorksMediaPageTitleContainer = styled.div`
  grid-area: T;
`

const CopyrightContainer = styled.div`
  grid-area: C;
`

const WorksMediaListContainer = styled.div`
  grid-area: W;
`

const WorksMediaPageLayout = styled(Grid)`
  min-height: 100vh;
  align-content: start;
  grid-template: "T B" "W W" "C C";
`

const WorksMediaPage: React.FC = () => {
    const [worksMedia, setWorksMedia] = useState<Media[] | undefined>(undefined)

    useEffect(() => {
        apiClient.listAllWorksMedia()
            .then(media => setWorksMedia(media))
    }, [])

    if (worksMedia === undefined) {
        return <Preloader/>
    }

    return <WorksMediaPageLayout>
        <WorksMediaPageTitleContainer>
            <Title>Works</Title>
        </WorksMediaPageTitleContainer>

        <BackToMainLinkContainer>
            <BackToMainLink/>
        </BackToMainLinkContainer>

        <WorksMediaListContainer>
            <WorksMediaList items={worksMedia}/>
        </WorksMediaListContainer>

        <CopyrightContainer><Copyright/></CopyrightContainer>
    </WorksMediaPageLayout>
}

export default WorksMediaPage
