import React from 'react'
import styled from 'styled-components'
import BackToMainLink from '../_shared/back-to-main-link'
import {MEDIA_BREAKPOINTS} from '../../const'

const NotFoundPageLayout = styled.div`
  min-height: 100vh;
  display: flex;
  gap: 1rem;
  padding: 1rem;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`

const NotFoundMessage = styled.div`
  text-align: center;
  opacity: 0.5;
`

const NotFoundSvg = styled.svg`
  max-width: 33.333%;
  
  @media(${MEDIA_BREAKPOINTS.LAPTOP}) { max-width: 50% }
  @media(${MEDIA_BREAKPOINTS.TABLET}) { max-width: 90%; }
  @media(${MEDIA_BREAKPOINTS.MOBILE}){ max-width: 100%; }
`

const NotFoundPage: React.FC = () => {
    return <NotFoundPageLayout>
        <NotFoundMessage>
            Oh, i’m sorry (not), but it’s look like you doing wrong. <br/>
            Or me, I don’t know. Whatever, it’s 404.
        </NotFoundMessage>

        <NotFoundSvg viewBox="0 0 505 114" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.14924 34.238C6.49462 59.5963 6.7459 85.0676 4.55194 110.388C4.16641 114.838 2.43689 101.641 2.32817 97.1758C1.95999 82.0562 4.72735 67.2792 12.2308 54.0101C17.2899 45.0637 25.1793 36.405 35.8201 34.634C44.3629 33.2121 48.0043 41.4427 47.7711 48.824C47.4932 57.618 41.8232 64.6921 33.2988 66.8521C24.6372 69.0469 18.9801 65.398 13.3737 59.2659" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <path d="M86.305 41.7819C85.6353 35.894 79.6936 30.9117 74.4854 28.7896C69.2265 26.6469 63.2861 27.3057 59.8177 32.0893C56.6345 36.4796 56.2198 44.1744 56.8365 49.3682C57.4552 54.5789 61.4712 58.0559 66.1028 59.9282C71.4927 62.107 76.1467 60.4269 78.8043 55.1957C80.8412 51.1863 81.1896 46.534 82.5642 42.2931C82.9407 41.1315 83.6859 40.2384 84.2437 39.1757C84.2887 39.09 84.4926 38.1105 84.6814 38.5177C84.9355 39.0656 85.089 39.7672 85.232 40.3388C87.8521 50.8079 90.2083 61.355 92.6845 71.8583" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <path d="M122.006 39.168C115.725 38.7582 108.342 37.0034 102.815 40.7651C96.6914 44.9328 97.0912 53.9283 101.118 59.4151C103.939 63.2593 108.834 65.7315 113.386 66.8411C116.827 67.6797 120.642 68.4515 123.351 65.6375C126.176 62.7032 126.614 58.5222 126.867 54.6414C127.101 51.0531 127.718 47.2124 126.34 43.7762C125.414 41.4674 124.35 39.9753 122.682 38.2852C122.086 37.6819 121.862 37.4621 121.483 36.6622C121.421 36.5301 120.991 36.311 120.998 36.2015C121.016 35.9308 121.25 36.6824 121.363 36.929C121.985 38.2885 122.715 39.5837 123.438 40.8921C129.768 52.3451 135.871 64.1823 139.846 76.6864C141.483 81.8325 145.311 92.0039 138.689 95.217C134.7 97.1523 130.01 97.4029 125.659 97.3535C120.055 97.2898 114.282 96.9646 108.782 95.8403C101.085 94.2666 93.7787 91.3181 88.7249 85.1941" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <path d="M147.26 59.3535C150.256 59.549 153.252 59.7623 156.25 59.9401C158.862 60.095 162.065 60.4601 164.529 59.3302C169.145 57.2132 170.395 51.1528 171.12 46.6982C171.993 41.3413 171.513 36.6919 166.343 33.8908C163.894 32.5639 161.389 31.9315 158.625 31.688C155.235 31.3892 151.543 30.8468 148.256 31.9897C138.363 35.4294 137.899 49.7137 140.24 58.0372C142.018 64.363 144.974 69.701 150.305 73.7128C155.449 77.5845 161.969 77.0185 168.059 76.7765" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <path d="M219.213 27.373C217.523 38.2176 218.097 49.5157 218.707 60.4224C218.962 64.9715 219.284 69.5168 219.495 74.0684C219.546 75.1738 219.983 78.444 219.665 77.3839C215.211 62.5226 216.626 41.2732 232.597 33.9809C237.947 31.5377 244.796 31.1907 247.717 37.2055C251.918 45.8558 251.296 57.602 249.872 66.8585C249.414 69.8321 249.412 73.7481 247.647 76.1376" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <path d="M273.527 41.6399C271.909 38.7706 269.338 38.8715 266.352 38.3514C263.787 37.9047 262.066 39.2442 260.898 41.5699C256.987 49.3587 258.365 57.6685 261.753 65.2786C263.033 68.154 266.841 70.6454 270.09 70.2192C275.574 69.4997 277.105 61.6996 278.012 57.5084C279.388 51.1558 280.811 42.9952 278.038 36.8075C275.723 31.6406 270.317 31.777 265.923 33.7913" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <path d="M293.014 14.0435C290.822 26.6292 287.895 39.2979 286.65 52.0129C286.305 55.5344 286.727 58.4675 287.998 61.8302C289.176 64.9449 292.253 74.3508 296.92 74.0767C299.16 73.9452 302.177 71.6825 303.976 70.503C306.374 68.9316 308.43 67.0689 310.605 65.2238" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <path d="M280.225 28.9424L306.345 33.4916" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <path d="M374.505 29.1039C374.543 22.8423 373.007 15.7426 367.013 12.4592C359.408 8.29384 354.509 16.6745 352.433 22.9446C347.906 36.6192 349.237 51.1869 351.847 65.0704C352.456 68.3153 352.845 72.0802 353.95 75.172" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <path d="M342.74 58.1089H360.014" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <path d="M382.082 43.3761C380.093 43.1351 376.896 42.1288 374.896 42.7525C372.142 43.6111 371.217 47.7539 370.544 50.0935C369.35 54.2479 368.292 58.9498 369.218 63.2641C370.368 68.6212 374.561 72.2403 379.067 74.9338C382.383 76.9165 388.264 79.5665 392.183 78.0071C398.642 75.4363 398.431 63.3556 398.352 57.8923C398.277 52.6717 398.005 43.8461 392.834 40.7225C390.847 39.5216 388.368 39.1814 386.09 39.051C383.269 38.8894 382.074 39.997 379.882 41.4909" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <path d="M405.296 42.1428C404.453 49.0969 402.732 56.5933 403.594 63.6083C404.06 67.403 406.211 70.439 409.792 71.8232C413.598 73.2946 415.468 72.1041 417.087 68.3909C420.231 61.1832 420.594 52.8081 421.528 45.0992C421.829 42.6192 421.724 40.1502 422.021 37.6952C422.117 36.9002 422.206 33.8599 422.281 36.288C422.392 39.8516 423.318 43.4721 423.817 46.9951C424.632 52.7591 425.11 58.4829 425.271 64.302C425.414 69.4519 425.992 75.049 425.37 80.1855" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <path d="M433.538 39.4955C432.635 46.9458 432.477 54.4087 432.936 61.9036C433.268 67.3347 433.868 73.0438 433.646 78.4907C433.629 78.9111 433.351 81.1087 433.55 79.4683C434.405 72.4116 434.811 65.4841 434.982 58.3748C435.129 52.2492 434.629 42.9486 439.441 38.2775C442.236 35.5638 448.37 34.0895 451.828 36.6088C453.944 38.1508 454.105 41.9943 454.401 44.3169C455.009 49.0907 454.795 53.8975 454.682 58.6939C454.466 67.8178 453.295 76.817 452.198 85.8647" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <path d="M481.203 60.2443C481.561 56.6979 480.441 55.1863 477.005 54.0704C474.325 53.2 470.632 51.2554 467.786 52.4136C466.252 53.0381 464.965 55.1482 464.636 56.7079C464.083 59.3264 464.371 63.0458 464.569 65.4224C465.132 72.1765 465.513 80.0869 467.995 86.4977C469.296 89.8604 472.409 83.0296 472.803 82.0671C475.937 74.4063 476.983 65.7958 478.276 57.6864C480.658 42.7364 482.567 27.6988 483.338 12.5735C483.514 9.11578 484.203 -1.24045 483.786 2.19659C483.492 4.62578 484.076 7.20636 484.373 9.59655C485.665 19.9907 486.614 30.4228 487.782 40.8312C489.445 55.6596 491.622 70.2616 494.4 84.9092" stroke="black" stroke-width="3" stroke-linecap="round"/>
        </NotFoundSvg>

        <BackToMainLink />
    </NotFoundPageLayout>
}

export default NotFoundPage
