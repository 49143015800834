import React, {FormEvent, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import TextField from '../../_shared/text-field'
import Button from '../../_shared/button'
import {Person} from '../../../api/client'
import {MEDIA_BREAKPOINTS} from '../../../const'

const SaveDialogContainer = styled.div`
  background: #fff;
  padding: 1rem;
`
const SaveDialogMessage = styled.p`margin-top: 0`;
const SaveDialogForm = styled.form`
  display: inline-flex;
  gap: 1rem;

  @media (${MEDIA_BREAKPOINTS.TABLET}) {
    display: flex;
    flex-direction: column;
  }
`

const SaveDialogFormFields = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 600px) {
    /* иначе форма плывет */
    flex-direction: column;
    width: 100%;
  }
`

const SaveDialogFormFieldContainer = styled.div`
  width: 275px;

  @media (${MEDIA_BREAKPOINTS.TABLET}) {
    width: 100%;
  }
`

export type SaveDialogProps = {
    onSave?: (data: Person) => void
}

const SaveDialog: React.FC<SaveDialogProps> = ({onSave}) => {
    const messageRef = useRef<HTMLParagraphElement>(null)
    const formRef = useRef<HTMLFormElement>(null)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')

    useEffect(() => {
        const resizeMessage = () => {
            if (!messageRef.current || !formRef.current) return;
            messageRef.current.style.width = `${formRef.current.clientWidth}px`;
        }

        window.addEventListener('resize', resizeMessage, false)
        resizeMessage()

        return () => {
            window.removeEventListener('resize', resizeMessage)
        }
    }, [])

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault()

        if (typeof onSave === 'function') {
            onSave({name, email})
        }
    }

    return <SaveDialogContainer>
        <SaveDialogMessage ref={messageRef}>
            Your name will will be placed in the the bottom right corner of the main page.
            Your email is only needed to contact you in case I publish the drawing.
        </SaveDialogMessage>
        <SaveDialogForm ref={formRef} onSubmit={handleSubmit}>
            <SaveDialogFormFields>
                <SaveDialogFormFieldContainer>
                    <TextField
                        name={'name'}
                        placeholder={'Name'}
                        required={true}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                </SaveDialogFormFieldContainer>
                <SaveDialogFormFieldContainer>
                    <TextField
                        type={'email'}
                        name={'email'}
                        placeholder={'E-Mail'}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </SaveDialogFormFieldContainer>
            </SaveDialogFormFields>
            <Button type={'submit'}>Send</Button>
        </SaveDialogForm>
    </SaveDialogContainer>
}

export default SaveDialog
